<template>
  <section class="py-20 content-grid--full bg-[#FBFBFD]">
    <div class="mx-auto max-w-7xl">
      <h2 class="uppercase text-2xl font-normal tracking-widest text-center text-[#19242F]" v-if="title">{{ title }}
      </h2>
      <div
        class="relative overflow-hidden py-2 mt-14 [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
        <div class="infinite-scroll flex justify-center items-top gap-16 w-[4064px]">
          <div v-for="(company, index) in repeatedCompanies" :key="index" class="flex justify-center items-top gap-16">
            <NuxtLink :to="company.href" class="transition group" v-if="company.href">
              <NuxtImg :src="company.logo" :alt="company.name + ' logotype'"
                class="slide object-contain aspect-[3/1] group-hover:scale-[1.1] transition-transform ease-in-out duration-300"
                loading="lazy" data-nosnippet />
              <!-- <div class="mt-auto flex justify-center">
                <span
                  class="text-primary-500 flex gap-2 items-center group-hover:underline focus:underline font-medium w-max pt-8 text-xs light-link">
                  Read success story
                  <Icon name="heroicons:arrow-right-16-solid" class="w-4 h-4" />
                </span>
              </div> -->
            </NuxtLink>
            <div class="transition group" v-else>
              <NuxtImg :src="company.logo" :alt="company.name + ' logotype'"
                class="slide object-contain aspect-[3/1] group-hover:scale-[1.1] transition-transform ease-in-out duration-300"
                loading="lazy" data-nosnippet />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  companies: Array,
  title: {
    type: String,
    default: "Trusted by"
  }
})
const trustedCompanies = props.companies || [{
  name: 'db-schenker',
  logo: '/assets/content/success-stories/schenker_logo.svg'
}, {
  name: 'intel',
  logo: '/assets/content/success-stories/intel-logo.svg'
}, {
  name: 'nato',
  logo: '/assets/content/success-stories/NATO-logo.svg'
}, {
  name: 'F1',
  logo: '/assets/content/success-stories/f1-logo.svg'
}, {
  name: 'la-poste',
  logo: '/assets/content/success-stories/laposte_logo.svg'
}, {
  name: 'transdev',
  logo: '/assets/content/success-stories/transdev-logo.svg'
}, {
  name: 'cma-cgm',
  logo: '/assets/content/success-stories/CMA_CGM_logo.svg'
}, {
  name: 'Mazars',
  logo: '/assets/content/success-stories/mazars-logo.png'
}, {
  name: 'CLS',
  logo: '/assets/content/cls.png'
}]

const repeatedCompanies = computed(() => {
  return [...trustedCompanies, ...trustedCompanies] // Duplicate the array
})
</script>

<style scoped>
.infinite-scroll {
  display: flex;
  animation: scroll 40s linear infinite;
}

.infinite-scroll:hover {
  animation-play-state: paused;
}

.slide {
  height: 100px;
  width: 250px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-50% - 2rem));
  }
}
</style>
